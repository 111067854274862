import * as React from "react"
import * as styles from "./css/repaymentVia.module.styl"
import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import { widthInfo } from "../util/index.js"
import Topbtn from "../components/topButton/topButton"
import Seo from "../components/seo"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
let width = widthInfo()
const RepaymentViaPage = () => {
  const PictureData = useStaticQuery(graphql`
    query RepaymentViaQuery {
      bannerPicture: file(relativePath: { eq: "paymentVia/bannerbg.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      activeLinePicture: file(
        relativePath: { eq: "paymentVia/activeLine.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      wa01Picture: file(relativePath: { eq: "paymentVia/wa1.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      wa02Picture: file(relativePath: { eq: "paymentVia/wa2.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      wa03Picture: file(relativePath: { eq: "paymentVia/wa3.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      wa05Picture: file(relativePath: { eq: "paymentVia/wa5.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      wa06Picture: file(relativePath: { eq: "paymentVia/wa6.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      wa07Picture: file(relativePath: { eq: "paymentVia/wa7.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      wa040101Picture: file(relativePath: { eq: "paymentVia/040101.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      wa040102Picture: file(relativePath: { eq: "paymentVia/040102.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      wa040103Picture: file(relativePath: { eq: "paymentVia/040103.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      wa040104Picture: file(relativePath: { eq: "paymentVia/040104.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      wa040105Picture: file(relativePath: { eq: "paymentVia/040105.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      wa040201Picture: file(relativePath: { eq: "paymentVia/040201.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      wa040202Picture: file(relativePath: { eq: "paymentVia/040202.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      personIcon: file(relativePath: { eq: "paymentVia/persionIcon.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      wa0101Picture: file(relativePath: { eq: "paymentVia/0101.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      wa0102Picture: file(relativePath: { eq: "paymentVia/0102.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      person2Icon: file(relativePath: { eq: "paymentVia/persionIcon2.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const {
    bannerPicture,
    activeLinePicture,
    wa01Picture,
    wa02Picture,
    wa03Picture,
    wa05Picture,
    wa06Picture,
    wa07Picture,
    wa040101Picture,
    wa040102Picture,
    wa040103Picture,
    wa040104Picture,
    wa040105Picture,
    wa040201Picture,
    wa040202Picture,
    personIcon,
    wa0101Picture,
    wa0102Picture,
    person2Icon,
  } = PictureData
  let gotop = () => {
    document.documentElement.scrollTop = 0
  }
  let scrollTop = 0
  return (
    <Layout>
      <Seo title="BarwaqtRepaymentMethods"></Seo>
      <header className={styles.paybanner}>
        <div className={styles.paybannercenter}>
          <div className={styles.paybannerInfo}>
            {width > 1024 ? (
              <div className={styles.paybannerTitle}>
                Barwaqt
                <br />
                Repayment Methods
              </div>
            ) : null}
            <Img
              className={styles.paybannerLogo}
              fluid={bannerPicture.childImageSharp.fluid}
              alt="A corgi smiling happily"
            />
          </div>
        </div>
      </header>
      {width > 1024 ? (
        // pc
        <div className={styles.paynav}>
          <div className={styles.payNav80}>
            <li className={styles.payli}>
              <div className={styles.payliInfo}>
                <span>
                  {" "}
                  <Link to="/oneBill"> Repayment Via 1Bill </Link>
                </span>
              </div>
            </li>
            <li className={styles.payli}>
              <div className={styles.payliInfo}>
                <span className={styles.active}>
                  {" "}
                  Repayment Via Mobile Wallet
                </span>
                <Img
                  className={styles.paybottomLine}
                  fluid={activeLinePicture.childImageSharp.fluid}
                  alt="A corgi smiling happily"
                />
              </div>
            </li>
            <li className={styles.payli}>
              <div className={styles.payliInfo}>
                <span>
                  {" "}
                  <Link to="/RepaymentByEasypaisa">
                    {" "}
                    Repayment Via Easypaisa
                  </Link>
                </span>
              </div>
            </li>
          </div>
        </div>
      ) : (
        // H5
        <div className={`${styles.paynav} `}>
          <div className={styles.payNav80}>
            <div className={`${styles.payli}  ${styles.payliInfo}`}>
              <span>
                {" "}
                <Link to="/oneBill">1Bill </Link>
              </span>
            </div>
            <div
              className={`${styles.payli} ${styles.payliInfo} ${styles.payliRightLine}`}
            >
              <span> MOBILE WALLET </span>
            </div>
            <div className={`${styles.payli} ${styles.payliInfo}`}>
              <span>
                {" "}
                <Link to="/RepaymentByEasypaisa"> EASYPAISA </Link>
              </span>
            </div>
          </div>
        </div>
      )}
      <div className={styles.content}>
        <div className={styles.contentTitle}>
          <span className={styles.contentTitleIcon}>
            {" "}
            {width > 1024 ? <span>STEP </span> : null}01
          </span>
          <span className={styles.contentTitleText}>
            Login To Your Barwaqt App by using your login details.
          </span>
        </div>
        <Img
          className={styles.pict477}
          fluid={wa01Picture.childImageSharp.fluid}
          alt="A corgi smiling happily"
        />
        <div className={styles.contentTitle}>
          <span className={styles.contentTitleIcon}>
            {width > 1024 ? <span>STEP </span> : null} 02
          </span>
          <span className={styles.contentTitleText}>
            Choose Repayment via Mobile Wallet from the options to repay.
          </span>
        </div>
        <Img
          className={styles.pict525}
          fluid={wa02Picture.childImageSharp.fluid}
          alt="A corgi smiling happily"
        />
        <div className={styles.contentTitle}>
          <span className={styles.contentTitleIcon}>
            {width > 1024 ? <span>STEP </span> : null} 03
          </span>
          <span className={styles.contentTitleText}>
            We Choose Mobile Wallet for the Repayment Method and click on “PAY
            NOW”.
          </span>
        </div>
        <Img
          className={styles.pict481}
          fluid={wa03Picture.childImageSharp.fluid}
          alt="A corgi smiling happily"
        />
        {/* 中间线1 */}
        <div className={styles.centerLine}>
          {/* <img src="../images/paymentVia/persionIcon.png"/> */}

          <Img
            className={styles.personIcon}
            fluid={personIcon.childImageSharp.fluid}
            alt="A corgi smiling happily"
          />
          <div className={styles.centerLinetext}>
            If you are a Telenor user.
          </div>
        </div>

        {/* <div className={styles.contentTitle}>
          <span className={styles.contentTitleIcon}>04</span>
          <span className={styles.contentTitleText}>There are two situations.</span>
        </div> */}
        {/* 里面的数据 */}
        <div className={styles.foursamllPart}>
          <div className={styles.secondTitle}>
            <span className={styles.secondTitleIcon}>01</span>
            <span className={styles.secondTitleText}>
              You will see a pop-up message. Confirm your payment by entering
              the 5-digit PIN.
            </span>
          </div>
          <Img
            className={styles.pict300}
            fluid={wa0101Picture.childImageSharp.fluid}
            alt="A corgi smiling happily"
          />
          <div className={styles.secondTitle}>
            <span className={styles.secondTitleIcon}>02</span>
            <span className={styles.secondTitleText}>
              You will see a pop-up message with information that your payment
              has been made.
            </span>
          </div>
          <Img
            className={styles.pict300}
            fluid={wa0102Picture.childImageSharp.fluid}
            alt="A corgi smiling happily"
          />
        </div>
        {/* 中间线2 */}
        <div className={styles.centerLine}>
          <Img
            className={styles.personIcon}
            fluid={person2Icon.childImageSharp.fluid}
            alt="A corgi smiling happily"
          />
          <div className={styles.centerLinetext}>
            If you are not a Telenor user.
          </div>
        </div>

        <div className={styles.foursamllPart}>
          <div className={`${styles.secondTitle}`}>
            <span className={styles.secondTitleIcon}>01</span>
            <span className={styles.secondTitleText}>
              There are two situations.
            </span>
          </div>
          <div className={`${styles.fourcontentTitle}`}>
            <span className={styles.fourIcon}>01</span>
            <span className={styles.fourIconText}>
              If you are not logged in to Easypaisa.
            </span>
          </div>
          <div className={`${styles.foursamllPart} ${styles.H5margin2rem}`}>
            <div className={styles.fourItemTitle}>
              <span className={styles.fourItemIcon}></span>
              <span className={styles.fourItemText}>
                Sign in to your Easypaisa account by entering the 5-digit PIN.
              </span>
            </div>
            <Img
              className={styles.pict312}
              fluid={wa040101Picture.childImageSharp.fluid}
              alt="A corgi smiling happily"
            />
            <Img
              className={styles.pict312}
              fluid={wa040102Picture.childImageSharp.fluid}
              alt="A corgi smiling happily"
            />
            <div className={styles.fourItemTitle}>
              <span className={styles.fourItemIcon}></span>
              <span className={styles.fourItemText}>
                Tap menu button on the top left corner.
              </span>
            </div>
            <Img
              className={styles.pict335}
              fluid={wa040103Picture.childImageSharp.fluid}
              alt="A corgi smiling happily"
            />
            {/* <div className={styles.or}>OR</div> */}
            <div className={styles.fourItemTitle}>
              <span className={styles.fourItemIcon}></span>
              <span className={styles.fourItemText}>
                Go to ‘My Approvals’ from the menu list and approve your
                payment!
              </span>
            </div>
            <Img
              className={styles.pict492}
              fluid={wa040104Picture.childImageSharp.fluid}
              alt="A corgi smiling happily"
            />
            <div className={styles.fourItemTitle}>
              <span className={styles.fourItemIcon}></span>
              <span className={styles.fourItemText}>
                Approve the payment here.
              </span>
            </div>
            <Img
              className={styles.pict312}
              fluid={wa040105Picture.childImageSharp.fluid}
              alt="A corgi smiling happily"
            />
          </div>
          {/* 0402里面的 */}
          <div className={`${styles.fourcontentTitle}`}>
            <span className={styles.fourIcon}>02</span>
            <span className={styles.fourIconText}>
              If you are logged in to Easypaisa.
            </span>
          </div>
          <div className={`${styles.foursamllPart} ${styles.H5margin2rem}`}>
            <div className={styles.fourItemTitle}>
              <span className={styles.fourItemIcon}></span>
              <span className={styles.fourItemText}>
                On EasyPaisa Wallet, Loan Amount Can be seen where the repayment
                needs to be done.
              </span>
            </div>
            <Img
              className={styles.pict477}
              fluid={wa040201Picture.childImageSharp.fluid}
              alt="A corgi smiling happily"
            />
            <div className={styles.fourItemTitle}>
              <span className={styles.fourItemIcon}></span>
              <span className={styles.fourItemText}>
                From EasyPaisa Wallet Confirm your Repayment by tapping PAY NOW
                option. Make sure you confirm your payment within 60 seconds.
              </span>
            </div>
            <Img
              className={styles.pict312}
              fluid={wa040202Picture.childImageSharp.fluid}
              alt="A corgi smiling happily"
            />
          </div>
          <div className={styles.secondTitle}>
            <span className={styles.secondTitleIcon}>02</span>
            <span className={styles.secondTitleText}>
              Transaction Successful Receipt will be received from EasyPaisa
              when the amount is transferred from Easypaisa Mobile Wallet.
            </span>
          </div>
          <Img
            className={styles.pict312}
            fluid={wa05Picture.childImageSharp.fluid}
            alt="A corgi smiling happily"
          />
        </div>
        <div className={styles.contentTitle}>
          <span className={styles.contentTitleIcon}>
            {width > 1024 ? <span>STEP </span> : null} 04
          </span>
          <span className={styles.contentTitleText}>
            Repayment Confirmation SMS can also be received on your mobile phone
            from EasyPaisa.
          </span>
        </div>
        <Img
          className={styles.pict312}
          fluid={wa06Picture.childImageSharp.fluid}
          alt="A corgi smiling happily"
        />
        <div className={styles.contentTitle}>
          <span className={styles.contentTitleIcon}>
            {width > 1024 ? <span>STEP </span> : null} 05
          </span>
          <span className={styles.contentTitleText}>
            Finally, you can see your Order has been setlled on Barwaqt App.
          </span>
        </div>
        <Img
          className={styles.pict312}
          fluid={wa07Picture.childImageSharp.fluid}
          alt="A corgi smiling happily"
        />
      </div>
      {width > 1024 ? <Topbtn></Topbtn> : null}
    </Layout>
  )
}

export default RepaymentViaPage
